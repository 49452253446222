import { render, staticRenderFns } from "./BtnCut.vue?vue&type=template&id=533746cb&scoped=true&"
var script = {}
import style0 from "./BtnCut.vue?vue&type=style&index=0&id=533746cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533746cb",
  null
  
)

export default component.exports